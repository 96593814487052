import Animation_1 from "../assets/lotties/blue-fire.json";
import Animation_2 from "../assets/lotties/pink-fire.json";
import Animation_3 from "../assets/lotties/red-fire-only.json";
import Money from "../assets/images/money.svg";
import Cash from "../assets/images/money.svg";

// 1 mb is  1048576 of bytes file limit 3mb  1048576 *3
export const LIMIT_FILE_UPLOAD_SIZE = "3145728";

export const BURN_YOUR_MEMORY = [
  {
    group: "Relationship Release",
    items: [
      { memory: "Ex Photos/History", isDisable: false },
      { memory: "Lost Love Letters", isDisable: false },
      { memory: "Shared Memories/Secrets", isDisable: false },
      { memory: "Broken Promises", isDisable: false },
    ],
  },
  {
    group: "Emotional Burden",
    items: [
      { memory: "Mistake Receipts", isDisable: false },
      { memory: "Shame Labels", isDisable: false },
      { memory: "Guilt Trips", isDisable: false },
      { memory: "Regretful Reminders", isDisable: false },
    ],
  },
  {
    group: "Fear & Anxiety",
    items: [
      { memory: "Fear & Phobia", isDisable: false },
      { memory: "Anxiety Cards", isDisable: false },
      { memory: "Worry Wrappers", isDisable: false },
      { memory: "Fear of Failure Tickets", isDisable: false },
    ],
  },
  {
    group: "Self-Liberation",
    items: [
      { memory: "Self-Doubt Certificates", isDisable: false },
      { memory: "Negative Self-Talk", isDisable: false },
    ],
  },
  {
    group: "Emotional Expression",
    items: [
      { memory: "Disappointment Explosions", isDisable: false },
      { memory: "Frustration Files", isDisable: false },
      { memory: "Anger Diaries", isDisable: false },
      { memory: "Sorrow Scrolls", isDisable: false },
    ],
  },
];

export const READ_MORE_LABEL = 'read more'

export const RELEASE_VALIDATION_MESSAGE = "Please write your emotions or upload a file to continue*"
export const DISCLAIMER_LABEL = "DISCLAIMER- This is addictive play at your own risk. No DATA stored. By not storing data, we minimize security risks and ensure regulatory compliance."
export const DISCLAIMER_CONTENT = "burnmy.money: Embers of Healing is designed for entertainment, it may encourage repetitive play. This game can be engaging and time-consuming. Play Responsibly: Set time limits and take breaks to avoid neglecting other aspects of your life. Recognize Signs of Addiction: Be mindful of your playing habits. If you feel you can't control your gameplay or it's negatively impacting your life, seek help from a professional. The information and services provided here are for informational and gaming purposes only. They are not intended to be a substitute for professional medical advice, diagnosis, or treatment. Do not disregard or delay seeking professional medical or mental health advice because of something you have read on this website. No Guarantee of Outcomes The services offered may not be suitable for everyone.  We cannot guarantee any specific results and any  decisions or actions you take based on the information provided are at your own risk."

export const INITIAL_ANIMATION_DURATION_SEC = 22;
export const PART_FIREING_ANIMATION = 3;
export const INITIAL_ANIMATION_PART_1_SEC = 10;
export const INITIAL_ANIMATION_PART_2_SEC = 15;

export const HOME_PAGE_CONTENT = "Burn 🔥 your Ex Photos / Memories / Emotional Debt. Release the weight of past relationships and transform your life. BURN IT🔥🔥🔥...Watch Them Burn to Ashes ⚱️"
export const HOME_PAGE_SOCIAL_RESPONSIBILITY_CONTENT = "Inspired by ancient cleansing rituals, this app lets you upload and burn memories in virtual flames. Transform pain into ashes, with the fire of awareness and you will be Feeeeling Goooood."
export const BURN_LABEL = "BURN"
export const NEGATIVITY_LABEL = "Money/Negatives";
export const MONEY_LABEL = "Memory/Heartache";
export const CORPORATE_RESPONSIBILITIES ="At Althena AI, Inc, we take great pride in our commitment to giving back to our community. As part of our dedication to making a positive impact, we allocate 90% of our profits towards various charitable causes."
export const USER_LABELS = {
  LOGIN: 'Login',
  USERNAME: 'User Name',
  PASSWORD: "Password",
  CONFIRM_PASSWORD: 'Confirm Password',
  MOBILE_NUMBER: 'Mobile Number',
  EMAIL_ID: 'Email ID',
  LOGIN_BUTTON: 'Login',
  CLEAR_BUTTON: 'Clear',
  LOGIN_WITH: 'Or Login with',
  SINGUP: "Signup",
  USERNAME_VALIDATION: '*Username should be 4 characters',
  USERNAME_EXIST: '*UserName already exist',
  PASSWORD_VALIDATION: '*Password should be 6 characters',
  CONFIRM_PASSWORD_VALIDATION: '*Confirm password Does not Match',
  // MOBILE_NUMBER_VALIDATION : '*Mobile should be 10 digit numbers',
  EMAILI_ID_VALIDATION: '*Invalid Mail Format',
  EMAILI_ID_EXIST: '*User email already exist',
}
export const CHOOSE_TYPE_ARRAY = [
  { img: Money, type: "Ḟire ₡urrency (Ḟ₡)", value: "choose money" },
  // {img: Mute, type: 'charecters', value: 'charecters'}
]

export const CASH_ARRAY = [
  // {img: Cash, amount: 2},
  { img: Cash, amount: 50 },
  { img: Cash, amount: 100 },
  { img: Cash, amount: 200 },
  { img: Cash, amount: 500 },
  { img: Cash, amount: 1000 },
  { img: Cash, amount: 1500 },
  { img: Cash, amount: 2000 },
  // { img: Cash, amount: "CUSTOM" },
]
export const START_LABEL = "start"
export const PLAY_LABEL = "Play"
export const BURN_YOUR_MEMORY_HEADING = "Release Your Negative Experience";

export const BURN_DESCRIPTION_LENGTH = "2000";
export const BURN_DESCRIPTION_PLACEHOLDER =
  "Describe the experience you want to release...";

export const BURN_DESCRIPTION_HEADING =
  "Write (or) Upload the Memory You Want to 🔥";
export const BURN_UPLOAD_TITLE = "Upload Your Exp.";
export const LIMIT = "limit (3MB)";

export const NEGATIVITY_RELEASE_CHOOSE_PEACE =
  "I Choose Freedom From My Negative Experiences *(No data stored)";
export const NEGATIVITY_RELEASE_CHOOSE_PEACE_BUTTON = "LET GO";

export const UPLOAD_FILE_TYPE = "image/jpeg, image/png, image/jpg";

export const BURN_YOUR_MEMORIES_SUB_TITLE =
  "Select One Experience You Wish to Release";
export const BURN_NEGATIVITY = "Your experience is released 🦋🧿✨😊";

export const CUSTOM_CURRENCY_TITLE = 'Choose Your Offering (Ḟ₡)'
export const FIRE_CURRENCY_LABEL = 'Offering Amount:'
export const CUSTOM_CURRENCY_VALIDATION = 'Currency value must be equal to or exceed 50.'
export const SUBMIT_BUTTON_LABEL = "Submit"

export const PAYMENT_TITLE = 'Payment Gateway'
export const PAYMENT_DESC = ''
export const PAYNOW_BUTTON = 'Pay Now'

export const RAZOR_PAY = {
  WELCOME_LABEL: "Welcome to burnmy.money",
  DESCRIPTION: 'burn negativity to make the world positive!!!'
}

export const MESSAGE_BURN_SCREEN = "Goodbye Heartache, Negatives & Pain. Hello, Inner Peace. It's a new life for me And I'm Feeeeling Goooood 🦋🧿✨😊";
export const MESSAGE_BURN_FINISH_SCREEN =
  "You are free from your past experience and you did a good thing of letting go of it. The bravest apologizes first, the strongest forgives first, and the happiest forgets first. Forgive others not for their sake, but for yours - to find peace. You are now Feeling Good 😊😊😊";
export const FIRE_ANIMATION_LOTTIE_FILES = {
  1: Animation_1,
  2: Animation_2,
  3: Animation_3,
};
export const PEACE_HEADER_LABEL = 'Spread joy! Share the link to make your friend happy.'
export const ABOUT_US = {
  LABEL: 'About us',
  CONTENT1: "Our mission is to support you in releasing negative moments and experiences, empowering you to move forward and feel good with positivity in your life. ",
  CONTENT2: "burnmy.money: Embers of Healing is an immersive psychological game that guides players through a transformative journey of self-discovery and healing. Within this virtual realm, players are encouraged to bravely confront their painful experiences, articulate their emotions, and embark on a cathartic process of release.",
  CONTENT3: "At the outset, players are invited to share their personal narratives of adversity and trauma in a supportive and non-judgmental environment. Through guided prompts and reflective exercises, they delve deep into their past, exploring the emotional contours of their experiences.In a symbolic gesture of empowerment, players are prompted to invest virtual currency—depicted as money—into their painful memories. Ultimately, the process of sharing personal narratives serves as a crucial step in the healing journey, allowing players to acknowledge their pain, validate their emotions, and begin the process of letting go and moving forward with renewed strength and resilience.",
  CONTENT4: "This act of monetary contribution serves as a metaphor for reclaiming agency and control over their own narratives.As the journey progresses, players are led to the culmination of their healing process: the ceremonial burning of their painful experiences. Through immersive visuals and evocative soundscapes, players witness the symbolic destruction of their past traumas, signifying the liberating act of letting go and embracing newfound emotional freedom.",
  CONTENT5: "Throughout the experience, players are enveloped in a supportive atmosphere, guided by soothing aesthetics and therapeutic ambiance. burnmy.money: Embers of Healing provides a safe and empowering space for individuals to confront their pain, find closure, embark on a path toward emotional well-being and renewal.",
  CONTENT6: "*PS: We don't store any of your experience data. Happy Releasing :) © althena ai inc"
}
export const REFUND_POILCY = {
  LABEL: 'Refund Policy',
  CONTENT1: 'The policy is that there are no refunds, at  any time, for any reason.',
  CONTENT2: 'This is fundamental to burning your negativity, money.',
  CONTENT3: 'No take-backs or cancellations; only forward progress.'
}

export const SHARE_MESSAGE = `Today, I released what no longer serves me, 
finding healing ❤️‍🩹 in the process. My pain vanished in flames 🔥, 
and I embraced peace ✌️ and joy 😊. My heart overflows with the positive rewards 
abound. Join me on this transformative journey by clicking the link.`

export const PRIVACY_POLICY = {
  TITLE: 'Privacy Policy',
  LIST1: { TITLE: 'Collecting Personal Information', DATA: 'We collect your name, email, and phone number when you provide it to us, such as when you fill out a contact form or sign up for our newsletter.' },
  LIST2: { TITLE: 'Use of Personal Information', DATA: 'We use the information we collect to communicate with you, send you updates and marketing materials, and improve our services.' },
  LIST3: { TITLE: 'Sharing of Personal Information', DATA: 'We do not share your personal information with third parties except as necessary to provide our services or as required by law.' },
  LIST4: { TITLE: 'Security', DATA: 'We take reasonable measures to protect your personal information from unauthorized access or disclosure.' },
  LIST5: { TITLE: 'Contact Us', DATA: 'If you have any questions about our Privacy Policy, contact us at ', EMAIL: 'connect@alphabsolutions.com' },
}

export const TERMSOFSERVICE = {
  TITLE: 'Terms of Services',
  LIST1: { TITLE: '1. Acceptance of Terms', DATA: 'By accessing or using our website, you agree to be bound by these Terms of Service.' },
  LIST2: { TITLE: '2. Use License', DATA: 'Permission is granted to temporarily download one copy of the materials (information or software) on our website for personal, non-commercial transitory viewing only.' },
  LIST3: { TITLE: '3. Limitations', DATA: 'In no event shall we or our suppliers be liable for any damages arising out of the use or inability to use the materials on our website, even if we have been notified orally or in writing of the possibility of such damage.' },
  LIST4: { TITLE: '4. Governing Law', DATA: 'We take reasonable measures to protect your personal information from unauthorized access or disclosure.' },
  LIST5: { TITLE: 'Contact Us', DATA: 'These terms and conditions are governed by and construed in accordance with the laws of your country and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.' },
}

export const CUSTOMER_SUPPORT = {
  TITLE: 'Customer Support',
  PHONE: {
    LABEL: 'Phone', VALUE: 'Coming Soon'
  },
  EMAIL: { 
    LABEL: 'Email', VALUE: 'connect@alphabsolutions.com' 
  },
  LIST1: { TITLE: 'Welcome to Our Support Center', DATA: "We're here to assist you with any inquiries or concerns you may have. Please feel free to explore our frequently asked questions or reach out to us directly for assistance." },
  LIST2: { TITLE: 'Frequently Asked Questions', QUESTION: 'What are your operating hours?', DATA: 'Our support team is available from 9am to 6pm, Monday to Friday.' },
  LIST3: {
    TITLE: 'Contact Us',
    LABEL1: 'Name:',
    LABEL2: 'Email:',
    LABEL3: 'Message:',
    BUTTON_LABEL: 'Send'
  }
}

export const AGREED_LABEL = "I agree to the"

export const CORPORATE_RESPONSIBILITY = {
  TITLE : 'Burn Away Your Emotional Debt NOW!',
  LIST1:'Release the weight of past relationships and transform your life.',
  LIST2: { TITLE: "Burn Away:", DATA: "Ex Photos, Emotional Baggage, Negative Memories, Heartache, Regret" },
  LIST3: { TITLE: 'Bring to the Fire of Awareness:', DATA: 'Your deepest fears, Unresolved emotions, Limiting beliefs, Self-doubt.' },
  LIST4: { TITLE: 'Watch Them Burn to Ashes! ', DATA: '' },
  LIST5: 'No Refunds. No Exceptions. At burnmy.money, we are committed to helping you release emotional baggage. No refunds will be issued, at any time, for any reason.',
  LIST6: "WHY??",
  LIST7: "Because, burning negativity is irreversible. Your commitment to transformation is crucial. No take-backs or cancellations; only forward progress."
}