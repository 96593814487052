const RegisterToast = {
  register: "Registerd Successfully!",
  error: "Error occur in Registering...",
};

const LoginToast = {
  login: "Login Successfully!",
  error: "Username/Password Incorrect",
};

const userApiToast={
  error:'Something went wrong!'
}

const GmailLoginToast = {
  login: "Login Successfully using Gmail!",
  error: "Error in Gmail Logging In...",
};

const FbLoginToast = {
  login: "Login Successfully using Fb!",
  error: "Error in Fb Logging In...",
};

const FILE_UPLOAD = {
  SUCCESS: "file uploaded",
  FAIL: "file must be less than 3 MB",
};

export {
  RegisterToast,
  LoginToast,
  GmailLoginToast,
  FbLoginToast,
  userApiToast,
  FILE_UPLOAD,
};
