import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FbLoginToast,
  GmailLoginToast,
  LoginToast,
  userApiToast,
} from "../../utils/toastMessages";
import {
  logInWithEmailAndPassword,
  logInWithGoogle,
  logInWithFacebook,
} from "../../firebase";
import {
  storeisUserAuthenticated,
  storeLogUserDetails,
  storeUserDetails,
  storeUserId,
} from "../../redux/reducers/userSlice";
import Login from "../../assets/images/login.svg";
import Google from "../../assets/images/google.svg";
import FaceBook from "../../assets/images/facebook.svg";
import Apple from "../../assets/images/apple.svg";
import AddUser from "../../assets/images/adduser.svg";
import QuestionMark from "../../assets/images/question.svg";
import User from "../../assets/images/user.svg";
import Eye from "../../assets/images/eye.svg";
import Eye_Close from "../../assets/images/eye_close.svg";
import { addUserApi, GetUserEmail } from "../../services/services";
import { USER_LABELS } from "../../utils/contants";

const LogIn = (props) => {
  const [userLoginDetail, setUserLoginDetail] = useState({
    email: "",
    password: "",
  });
  const [validEmailErr, setValidEmailErr] = useState(false);
  const [validPasswordErr, setValidPasswordErr] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isForgotModal, setIsForgotModal] = useState(false);
  const isUserLogin = useSelector((state) => state.user.isUserAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const buttonRef = useRef(null);
  const passwordRef = useRef(null);

  const handleChange = async (e) => {
    let { name, value } = e.target;
    setUserLoginDetail({ ...userLoginDetail, [name]: value });
    switch (name) {
      case "email":
        if (name === "email" && value.length < 4 && value !== "") {
          setValidEmailErr(true);
        } else {
          setValidEmailErr(false);
        }
        let special = value.replace(/[^\w\s]/gi, "");
        let data = special.replace(/[^\S*$]/gi, "");
        setUserLoginDetail({ ...userLoginDetail, [name]: data });
        break;
      case "password":
        if (name === "password" && value.length < 6 && value !== "") {
          setValidPasswordErr(true);
        } else {
          setValidPasswordErr(false);
        }
        break;
    }
  };
  const handleLogin = async () => {
    let userPayload = {
      fieldName: "username",
      userValue: userLoginDetail.email,
    };
    try {
      let selectedUser = await GetUserEmail(userPayload);
      if(selectedUser){
        const logPayload = {
          email: selectedUser?.email,
          password: userLoginDetail.password,
        };
        let response = await logInWithEmailAndPassword(logPayload);
        if (response) {
          const userData = {
            name: selectedUser?.username,
            email: selectedUser?.email,
          };
          toast.success(LoginToast.login);
          dispatch(storeisUserAuthenticated(true));
          dispatch(storeUserDetails(response));
          dispatch(storeLogUserDetails(userData));
          dispatch(storeUserId(selectedUser._id));
          navigate("/getburn");
        } else {
          toast.error(LoginToast.error, "auth/wrong-password");
          navigate("/");
        }
      }else{
        toast.error(userApiToast.error,'');
      }
    } catch (err) {
      console.log(err, "err in get user email");
    }
    

  };
  const handleGoogleLogin = async () => {
    let googRes = await logInWithGoogle();
    if (googRes) {
      const userPayload = {
        username: googRes?.user?.displayName,
        password: googRes?.user?.uid,
        confirmpassword: googRes?.user?.uid,
        // mobilenumber: '',
        email: googRes?.user?.email,
      };
      let userRes;
      try {
        userRes = await addUserApi(userPayload);
        if (userRes) {
          console.log(userRes, "add user api success google");
        }
      } catch (err) {
        console.log(err, "add user api error google");
      }
      let userId = Object.keys(userRes).length > 0 ? userRes._id : "";
      const userData = {
        name: userRes?.username || "",
        email: userRes?.email || "",
      };
      toast.success(GmailLoginToast.login);
      dispatch(storeisUserAuthenticated(true));
      dispatch(storeUserDetails(googRes));
      dispatch(storeLogUserDetails(userData));
      dispatch(storeUserId(userId));
      navigate("/getburn");
    } else {
      toast.error(GmailLoginToast.error, "err in google login");
      navigate("/");
    }
  };
  const handleFacebookLogin = async () => {
    let fbRes = await logInWithFacebook();
    if (fbRes) {
      const userPayload = {
        username: fbRes?.user?.displayName,
        password: fbRes?.user?.uid,
        confirmpassword: fbRes?.user?.uid,
        // mobilenumber: '',
        email: fbRes?.user?.email,
      };
      let userRes;
      try {
        userRes = await addUserApi(userPayload);
        if (userRes) {
          console.log(userRes, "add user api success fb");
        }
      } catch (err) {
        console.log(err, "add user api error fb");
      }
      let userId = Object.keys(userRes).length > 0 ? userRes._id : "";
      const userData = {
        name: userRes?.username || "",
        email: userRes?.email || "",
      };
      toast.success(FbLoginToast.login);
      dispatch(storeisUserAuthenticated(true));
      dispatch(storeUserDetails(fbRes));
      dispatch(storeLogUserDetails(userData));
      dispatch(storeUserId(userId));
      navigate("/getburn");
    } else {
      toast.error(FbLoginToast.error, "err in fb login");
      navigate("/");
    }
  };
  const handleClear = () => {
    setUserLoginDetail({ email: "", password: "" });
  };
  useEffect(() => {
    if (isUserLogin) {
      navigate("/getburn");
    } else {
      navigate("/");
    }
  }, []);

  const handleKeyDown = (event, name) => {
    console.log(event.target.name);
    console.log("Jaff Testing")

    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission if any
      if (event.target.name == "password") {
        buttonRef.current.focus();
      } else if (event.target.name == "email") {
        passwordRef.current.focus();
      }
    }
  };

  return (
    <section className="bg-white rounded">
      <div className="w-[258px] min-h-[331px] h-[70%] bg-white rounded-lg px-8 py-10 sm:w-[340px]">
        <div className="flex justify-between w-[30%] m-auto">
          <img src={Login} alt="login" className="h-6 w-6" />
          <h1 className="text-xl font-[sfPro-bold] text-bgMain">
            {USER_LABELS.LOGIN}
          </h1>
        </div>
        <div className="flex flex-col justify-around min-h-[160px] h-[30%]">
          <div>
            <div className="flex justify-between w-4/5">
              <h3 className="font-[sfPro-bold] text-[15px] text-bgMain">
                {USER_LABELS.USERNAME}
              </h3>
            </div>
            <div className="flex">
              <input
                type="text"
                className="w-full border-b-2 border-b-bgMain focus:outline-none font-[sfPro-bold] text-bgMain text-[18px] inputbase pb-1"
                name="email"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={userLoginDetail.email}
              />
              <div className="border-b-2 border-b-bgMain">
                <img src={User} alt="user" className="h-4 w-4 mr-2" />
              </div>
            </div>
            {validEmailErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.USERNAME_VALIDATION}
              </span>
            )}
          </div>
          <div>
            <div className="flex justify-between w-full">
              <h3 className="font-[sfPro-bold] text-bgMain">
                {USER_LABELS.PASSWORD}
              </h3>
            </div>
            <div className="flex">
              <input
                type={passwordShow ? "text" : "password"}
                // style={{WebkitTextSecurity: 'square'}}
                className="w-full border-b-2  border-b-bgMain focus:outline-none font-[sfPro-bold] text-bgMain inputbase pb-1"
                name="password"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={passwordRef}
                value={userLoginDetail.password}
              />
              <div className="border-b-2 border-b-bgMain flex items-center">
                <img
                  src={passwordShow ? Eye : Eye_Close}
                  alt="eye"
                  className="h-5 w-4 hover:cursor-pointer mr-2"
                  onClick={() => setPasswordShow(!passwordShow)}
                />
              </div>
            </div>
            {validPasswordErr && (
              <span className="text-red text-sm font-[sfPro-regular]">
                {USER_LABELS.PASSWORD_VALIDATION}
              </span>
            )}
          </div>
        </div>
        {/* <div className="font-[sfPro-regular] text-[15px] text-bgMain pb-2 flex justify-end">
                        <img src={QuestionMark} alt="question_mark" />
                        <span className="mx-1">Forgot</span>
                        <Link to="/reset" className="text-bgGreen underline">Password</Link>
                        <span className="text-bgGreen underline opacity-70 hover:cursor-pointer">Password</span>
                    </div> */}
        <div className="flex w-full justify-center my-5">
          <div className="flex w-[90%] justify-between gap-3">
            <button
              ref={buttonRef}
              className={`w-28 h-10 rounded-md text-white font-[sfPro-medium]
                        ${
                          userLoginDetail.email !== "" &&
                          userLoginDetail.password !== "" &&
                          !validEmailErr &&
                          !validPasswordErr
                            ? "bg-bgGreen"
                            : "bg-bgGreen opacity-50"
                        }`}
              onClick={handleLogin}
              disabled={
                userLoginDetail.email !== "" &&
                userLoginDetail.password !== "" &&
                !validEmailErr &&
                !validPasswordErr
                  ? false
                  : true
              }
            >
              {USER_LABELS.LOGIN}
            </button>
            <button
              className={`w-28 h-10 rounded-md text-white font-[sfPro-medium]
                        ${
                          userLoginDetail.email !== "" ||
                          userLoginDetail.password !== ""
                            ? "bg-textBlack"
                            : "bg-textBlack opacity-50"
                        }`}
              onClick={handleClear}
              disabled={
                userLoginDetail.email !== "" || userLoginDetail.password !== ""
                  ? false
                  : true
              }
            >
              {USER_LABELS.CLEAR_BUTTON}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center mt-5">
          <hr className="w-[13%]" />
          <p className="text-bgMain text-sm font-[sfPro-regular] mx-3">
            {USER_LABELS.LOGIN_WITH}
          </p>
          <hr className="w-[13%]" />
        </div>
      </div>

      <div className="flex justify-center m-auto h-7">
        <div className="flex justify-end items-center w-full">
          <div className="hover:cursor-pointer">
            <img src={Google} alt="google" onClick={handleGoogleLogin} />
          </div>
          {/* <div 
                        className="hover:cursor-pointer"
                        >
                            <img src={FaceBook} alt="facebook" className="opacity-90"
                            onClick={handleFacebookLogin}
                            />
                        </div> */}
          {/* <div className="hover:cursor-not-allowed">
                            <img src={Apple} alt="apple" className="opacity-30" 
                            onClick={()=>{}}/>
                        </div> */}
        </div>
        <div className="flex justify-between w-full">
          <div></div>
          <div
            className="flex items-center rounded-l-3xl bg-bgGreen w-9 h-8 hover:w-[85px] hover:delay-50 group hover:cursor-pointer"
            onClick={() => props.regShow(true)}
          >
            <img
              src={AddUser}
              alt="adduser"
              className=" absolute h-5 w-5 ml-2"
            />
            <p className="hidden group-hover:block absolute h-5 w-5 ml-7 pl-1 pr-1.5 font-[sfPro-medium] text-sm text-bgMain">
              {USER_LABELS.SINGUP}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogIn;
